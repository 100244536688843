import { useEffect, useState } from "react";
import axios from "axios";

import { faBusAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactPlayer from "react-player/youtube";

import TextTransition from "react-text-transition";

import AnalogClock from "analog-clock-react";

import useSound from "use-sound";

import notificationSound from "./sounds/notif.mp3";
import shuttleArrivedEN from "./sounds/airport-shuttle-arr-en.mp3";
import shuttleArrivedDE from "./sounds/airport-shuttle-arr-de.mp3";
import shuttleArrivedCZ from "./sounds/airport-shuttle-arr-cz.mp3";
import shuttleArrivedHU from "./sounds/airport-shuttle-arr-hu.mp3";
import shuttleArrivedSL from "./sounds/airport-shuttle-arr-sl.mp3";
import shuttleArrivedSK from "./sounds/airport-shuttle-arr-sk.mp3";
import pickupLoc from "./assets/pandaparken.jpg";

import "./App.css";

const LIST_LENGTH = 13;
const PAGE_NUMBERS = 3;
const PAGE_TIME = 30;

const ARRIVING_SOON_TEXTS = [
	"Arriving Soon",
	"Kommt bald an",
	"Hamarosan Megérkezik",
	"Prichádza Čoskoro",
	"Přijede Brzy",
	"Kmalu Prispe",
];
const ARRIVED_TEXTS = ["Arrived", "Angekommen", "Megérkezett", "Prišiel", "Přišel", "Prispel"];
const LEAVING_SOON_TEXTS = [
	"Leaving Soon",
	"Fährt Bald Ab",
	"Hamarosan Indul",
	"Čoskoro Odíde",
	"Brzy Odjede",
	"Kmalu Odhaja",
];

function App() {
	const [flightData, setFlightData] = useState(null as null | Array<any>);

	const [busData, setBusData] = useState(false);
	const [notifyCount, setNotifyCount] = useState(0);

	const [arrSoonIndex, setArrSoonIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(
			() => setArrSoonIndex((index) => (index + 1) % ARRIVING_SOON_TEXTS.length),
			3000 // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);

	const [playNotification] = useSound(notificationSound);
	const [playShuttleArrivedEN] = useSound(shuttleArrivedEN);
	const [playShuttleArrivedDE] = useSound(shuttleArrivedDE);
	const [playShuttleArrivedCZ] = useSound(shuttleArrivedCZ);
	const [playShuttleArrivedHU] = useSound(shuttleArrivedHU);
	const [playShuttleArrivedSL] = useSound(shuttleArrivedSL);
	const [playShuttleArrivedSK] = useSound(shuttleArrivedSK);

	const searchParams = new URLSearchParams(document.location.search);

	const [streamVol, setStreamVol] = useState(parseFloat(searchParams.get("musicVolume") ?? "0.6"));
	const [actVol, setActVol] = useState(parseFloat(searchParams.get("musicVolume") ?? "0.6"));

	const wait = (ms: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

	const refreshBusDataLoop = async () => {
		try {
			const data = await axios.get(
				`https://pandaparken.work/admin/get_bus_arrivals_at_parking?parking_no=${searchParams.get("parkingNo") ?? "1"}`
			);
			if (data.status === 200) {
				const timeDiff =
					(new Date().getTime() - new Date().getTimezoneOffset() * 60) / 1000 - parseInt(data.data.timestamp);
				setBusData(timeDiff <= 80);
				console.log(timeDiff);
				if (timeDiff <= 60) setNotifyCount(1);
				else if (timeDiff <= 80) setNotifyCount(2);
				else setNotifyCount(0);

				if (localStorage.getItem("version") !== data.data.version) {
					localStorage.setItem("version", data.data.version);
					window.location.reload();
				}
			}
			setTimeout(() => {
				refreshBusDataLoop();
			}, 5000);
		} catch (err) {
			setTimeout(() => {
				refreshBusDataLoop();
			}, 2000);
		}
	};

	const refreshFlightDataLoop = async (flightPage: number) => {
		try {
			let data = (
				await axios.get(`https://www.viennaairport.com/jart/prj3/va/data/flights/out.json?dummy=R9e927f2X5dfc514`)
			).data.monitor.departure;

			data = data
				.filter((flight: any) => {
					return new Date(flight.actual) > new Date();
				})
				.sort((a: any, b: any) => new Date(a.actual).getTime() - new Date(b.actual).getTime())
				.slice(flightPage * LIST_LENGTH, (flightPage + 1) * LIST_LENGTH);
			setFlightData(data);
			setTimeout(() => {
				refreshFlightDataLoop((flightPage + 1) % PAGE_NUMBERS);
			}, PAGE_TIME * 1000);
		} catch (err) {
			setTimeout(() => {
				refreshFlightDataLoop(flightPage);
			}, 3000);
		}
	};

	useEffect(() => {
		refreshBusDataLoop();
		refreshFlightDataLoop(0);
	}, []);

	const lowerVolume = async () => {
		let vol = actVol;
		if (streamVol < vol) {
			while (vol > streamVol) {
				vol -= 0.01;
				setActVol(vol);
				await wait(12);
			}
		} else if (streamVol > vol) {
			while (vol < streamVol) {
				vol += 0.01;
				setActVol(vol);
				await wait(12);
			}
		}
	};

	useEffect(() => {
		lowerVolume();
	}, [streamVol]);

	const notifyArrived = async () => {
		setStreamVol(parseFloat(searchParams.get("musicVolume") ?? "0.6") * 0.3);
		await wait(1000);
		await playNotification();
		await wait(2000);
		await playShuttleArrivedDE();
		await wait(6000);
		await playShuttleArrivedEN();
		await wait(6000);
		await playShuttleArrivedHU();
		await wait(6800);
		await playShuttleArrivedSK();
		await wait(6800);
		await playShuttleArrivedCZ();
		await wait(6800);
		await playShuttleArrivedSL();
		await wait(6800);
	};

	const notifyLeaving = async () => {
		setStreamVol(parseFloat(searchParams.get("musicVolume") ?? "0.6") * 0.3);
		await wait(1000);
		await playNotification();
	};

	useEffect(() => {
		if (notifyCount === 1) notifyArrived();
		else if (notifyCount === 2) notifyLeaving();
		else if (notifyCount === 0) setStreamVol(parseFloat(searchParams.get("musicVolume") ?? "0.2"));
	}, [notifyCount]);

	function formatTimeDiff(t1: number, t2: number) {
		const diff = Math.max(t1, t2) - Math.min(t1, t2);
		const SEC = 1000,
			MIN = 60 * SEC,
			HRS = 60 * MIN;

		const hrs = Math.floor(diff / HRS);
		const min = Math.floor((diff % HRS) / MIN).toLocaleString("en-US", { minimumIntegerDigits: 2 });

		return `${hrs ? `${hrs}h ` : ""}${min}min`;
	}

	return (
		<div className=" h-full w-full overflow-hidden">
			<div className="fixed opacity-0 pointer-events-none">
				<ReactPlayer
					volume={actVol}
					muted={false}
					playing={true}
					loop
					url={searchParams.get("streamUrl") ?? "https://www.youtube.com/watch?v=fEvM-OUbaKs"}
				/>
			</div>
			<div className="grid grid-cols-12 items-center h-full">
				<div className="col-span-10 border-r border-gray-800 h-full">
					<div className="grid grid-cols-12 p-4 bg-gray-800 text-white">
						<h1 className="col-span-1">Time</h1>
						<h1 className="col-span-2">To</h1>
						<h1 className="col-span-1">Flight</h1>
						<h1 className="col-span-1">Terminal</h1>
						<h1 className="col-span-1">Gate</h1>
						<h1 className="col-span-3">Airline</h1>
						<h1 className="col-span-2">Status</h1>
					</div>
					<div className="text-2xl">
						{flightData &&
							flightData.map((flight: any) => (
								<div
									key={flight.fn}
									className={`grid px-4 items-center grid-cols-12 py-3 border-b border-gray-400
								${flight.status.description && flight.status.description.includes("boarding") && "bg-yellow-50"}
								${flight.status.description && flight.status.description.includes("gate closed") && "bg-red-50"}
								${flight.status.description && flight.status.description.includes("gate open") && "bg-green-50"}`}
								>
									<h1 style={{ lineHeight: 0.8 }} className="col-span-1 font-extrabold flex flex-col justify-center h-full">
										{new Date(flight.actual).toTimeString().slice(0, 5)} <br />{" "}
										<span className="text-sm m-0">
											{formatTimeDiff(new Date(flight.actual).getTime(), new Date().getTime())}
										</span>
									</h1>
									<h1 className="col-span-2">{flight.destinations[0].nameEN}</h1>
									<h1 className="col-span-1 text-xl">{flight.fn}</h1>
									<h1 className="col-span-1 text-xl">{flight.checkin.terminal}</h1>
									<h1 className="col-span-1">{flight.gate}</h1>
									<h1 className="col-span-3">{flight.airline.name}</h1>
									<h1 className="col-span-2 text-xl font-bold">
										{flight.status.description && flight.status.description.toUpperCase()}
									</h1>
								</div>
							))}
					</div>
				</div>
				<div className="col-span-2 p-8 flex flex-col justify-center items-center gap-8 w-full h-full text-gray-800 -z-50">
					<AnalogClock
						width="160px"
						border={true}
						borderColor="rgb(31 41 55)"
						baseColor="#ffffff"
						centerColor="rgb(31 41 55 0)"
						centerBorderColor="rgb(31 41 55 0)"
						handColors={{
							second: "#" + (searchParams.get("color") ?? "d81c7a"),
							minute: "rgb(31 41 55)",
							hour: "rgb(31 41 55)",
						}}
					/>
					<h1 className="font-extrabold text-6xl text-center w-full">{new Date().toTimeString().slice(0, 5)}</h1>
				</div>
			</div>
			<div
				className={`fixed bottom-0 left-0 w-full z-50 
        ${!busData && `p-8 h-32 ml-0 pb-10`}
      ${busData && `p-20 h-screen ml-auto mr-auto`}
       text-white flex items-end gap-12`}
				style={{ backgroundColor: busData ? `#${searchParams.get("color") ?? "d81c7a"}` : "rgb(31 41 55)" }}
			>
				{searchParams.get("parkingNo") === "1" && (
					<img
						alt="pandaparken"
						src={pickupLoc}
						className={`absolute right-0 top-0 h-3/4 w-full object-cover ${busData ? "opacity-100" : "opacity-0"}`}
					/>
				)}
				<FontAwesomeIcon className={`${busData ? "text-9xl" : "text-5xl"}`} icon={faBusAlt} />
				<h1
					className={`overflow-visible ${
						!busData ? "text-5xl" : "text-9xl"
					} font-extrabold font-transition flex items-center`}
				>
					<TextTransition className="flex items-center">
						{!busData
							? ARRIVING_SOON_TEXTS[arrSoonIndex]
							: notifyCount === 1
							? ARRIVED_TEXTS[arrSoonIndex]
							: LEAVING_SOON_TEXTS[arrSoonIndex]}
					</TextTransition>
				</h1>
			</div>
		</div>
	);
}

export default App;

